import React from "react";
import { StaticQuery, graphql } from "gatsby";

import ButtonRow from "../../components/button_row";
import Button from "../../components/button";
import Layout from "../../components/layout";

import beggar from "../../images/beggar.svg";
import home from "../../images/home.svg";
import shakingHands from "../../images/shaking_hands.svg";
import student from "../../images/student.svg";

const query = graphql
`
query pisma {
  allMarkdownRemark(filter: {frontmatter: {path: {eq: "/orodjarna/pisma"}}}) {
    edges {
      node {
        id
        frontmatter {
          path
          navTitle
        }
        excerpt
        html
        rawMarkdownBody
      }
    }
  }
}
`;

const PismaPage = props => (
  <Layout navTitle={props.data.frontmatter.navTitle}>
    <div dangerouslySetInnerHTML={{ __html: props.data.html }}/>
    <ButtonRow>
      <Button
        img={beggar}
        flavorText="Pismo občini"
        link="/orodjarna/pisma/obcini"
      />
    
      <Button
        img={home}
        flavorText="Pismo najemodajalcu"
        link="/orodjarna/pisma/najemodajalcu"
      />

      <Button
        img={shakingHands}
        flavorText="Skupinsko pismo"
        link="/orodjarna/pisma/skupinsko"
      />

      <Button
        img={student}
        flavorText="Študentsko pismo"
        link="/orodjarna/pisma/studentsko"
      />
    </ButtonRow>
  </Layout>
);

export default () => (
  <StaticQuery
    query={query}
    render={ data => (
      <PismaPage data={data.allMarkdownRemark.edges[0].node}/>
    )}
  />
);
